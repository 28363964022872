.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    .ti-reload {
      font-size: 3rem;
      color: #3949ab;
      animation: spin 1s linear infinite;
    }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.modal-content {
    background-color: #fefefe;
    margin: 5% auto;
    padding: 20px;
    width: 80%;
    max-width: 400px;
}

.input {
    margin-bottom: 10px;
    width: 100%;
    padding: 5px;
    border-radius: 25;
}

.field {
    display:flex;
    margin-bottom: 10px;
    padding: 5px;
    border-style: solid;
    border-width: 2px;
    border-color: #17d20d;
    border-radius: 10px;
    color: #17d20d;
    background-color: transparent;
    min-height: 50px;
}

.close-icon {
    width: 100%;
   display: flex;
   justify-content: end;
    
}

.field-text {
    width: 100%;
}


button {
    padding: 5px 10px;
    margin-right: 10px;
}


.keyboard-button{
    
    display: grid;
    grid-template-columns: repeat(4, 50px);
    gap: 10px;
    justify-content: center;
      
}
.selected-tab{
    background-color: inherit;
    border-style: solid;
    border-width: 0 0 1px 0;
    border-color: #A73636;

}

.not-selected-tab{
    background-color: inherit;
    border-style: solid;
    border-width: 0 0 0px 0;
    border-color: #A73636;

}
.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.modal-content {
    background-color: #fefefe;
    margin: 5% auto;
    padding: 20px;
    width: 80%;
    max-width: 400px;
}

.input {
    margin-bottom: 10px;
    width: 100%;
    padding: 5px;
    border-radius: 25;
}

.close-icon {
    width: 100%;
   display: flex;
   justify-content: end;
    
}

.field-text {
    width: 100%;
}


button {
    padding: 5px 10px;
    margin-right: 10px;
}

.tab-container{
    width: 100%;
    padding: 0 10px 0 30px;
    display: flex;
    justify-content: start;
}

.order-accept-button{
    border-radius: 20px;
    background-color: rgb(98, 192, 5);
    color: aliceblue;
}

.order-end-button{
    width:50%;
    border-radius: 20px;
    background-color: rgb(196, 120, 50);
    color: aliceblue;
}

.order-cancel-button{
    width:50%;
    border-radius: 20px;
    background-color: rgb(237, 196, 91);
    color: aliceblue;
}
.x-dropdown-container{
    width: 100%;
    position:relative;
}

.x-dropdown{
    height: 6vh;
    border-style: solid;
    border-color: rgb(219, 218, 218);
    border-radius: 5px;
    background-color: white;
}

.x-dropdown-menu{
    position:fixed;
    z-index: 999;
    width: 40vw;
    height: auto;
    background-color: white;
}

.x-dropdown-option{
    height: 6vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-width: 0 0 1px 0;
    border-color: rgb(219, 218, 218);
    cursor: pointer;
}

.x-dropdown-option:hover{
    background-color: rgb(235, 233, 233);
}
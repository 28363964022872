.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.modal-content {
    background-color: #fefefe;
    margin: 5% auto;
    padding: 20px;
    width: 80%;
    max-width: 400px;
}

.input {
    margin-bottom: 10px;
    width: 100%;
    padding: 5px;
    border-radius: 25;
}

.field {
    display:flex;
    margin-bottom: 10px;
    padding: 5px;
    border-style: solid;
    border-width: 2px;
    border-color: #17d20d;
    border-radius: 10px;
    color: #17d20d;
    background-color: transparent;
    min-height: 50px;
}

.close-icon {
    width: 100%;
   display: flex;
   justify-content: end;
    
}

.field-text {
    width: 100%;
}


button {
    padding: 5px 10px;
    margin-right: 10px;
}

.file-input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; /* Ajusta el tamaño del cuadrado */
    height: 200px;
    border: 2px dashed #414141; /* Bordes discontinuos */
    border-radius: 8px; /* Bordes redondeados */
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .file-input-container:hover {
    background-color: #fff2f0; /* Fondo al pasar el cursor */
    border-color: #c04434; /* Cambia el color del borde */
  }
  
  .hidden-input {
    display: none; /* Oculta el input real */
  }
  
  .file-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #414141; /* Color del texto e ícono */
    font-size: 16px;
    text-align: center;
  }
  
  .file-icon {
    font-size: 48px; /* Tamaño del ícono */
    margin-bottom: 8px;
    color: #414141;
  }
  
  .file-label span {
    font-size: 14px;
    font-weight: 500;
  }
  
  /* Responsivo: reduce el tamaño en pantallas pequeñas */
  @media (max-width: 600px) {
    .file-input-container {
      width: 150px;
      height: 150px;
    }
  
    .file-icon {
      font-size: 36px;
    }
  
    .file-label span {
      font-size: 12px;
    }
  }
  
@import 'assets/scss/app.scss';

.on-hover-pointer{
    cursor: pointer;
}

.btn-fixed-bottom-right {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 2;
}

.btn-right-container {
    display:flex;
    justify-content: end;
    width: 100%;
    height: 8vh;
}

.my-modal-container{
    position: fixed;
    top:0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
    overflow-y: auto;
}

.my-modal-container-invisible{
    position: fixed;
    top:0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0);
    overflow-y: auto;
}

.hidden{
   display: none;
}

.flex{
    display: flex;
}

.table-container{
    height: 60 vh;
    overflow: auto;
}

.details-attribute-container{
    display:flex;
    align-items: flex-end;
    gap: 5px;
    
}

.icon-form-field-container{
    display: flex;
    border-style: solid;
    border-color: rgb(182, 180, 177);
    border-radius: 10px;
    align-items: center;
    
}

.icon-form-field{
    border-style: none;
    width: 100%;
    height: 100%;
}

.toggle-btn-container{
  display: flex;
  height: 100%;
  align-items: center;
  margin-left: 4vw;
  margin-right: 4vw;
}

.toggle-btn-bar{
  height: 4vh;
  width: 3vw;
  background-color: #9e9c9c;
  border-radius: 10px;
  display: flex;
  align-items: center;
  position: relative;
}

.toggle-btn-bar-checked{
  @extend .toggle-btn-bar;
  background-color: #575656;
  
}

.toggle-btn{
    position: absolute;
    cursor: pointer;
    border: none;
    border-radius: 50%;
    color: #b67575;
    background-color: #b67575;
    height: 3vh;
    width: 3vh;
    padding: 0;
    font-size: 24px;
    transition: all 0.5s ease;
    right: 20%;
  }

.toggle-btn-checked{
  @extend .toggle-btn;
  right: -25%;
  color: #ef2d2d;
  background-color: #ef2d2d; 
}
  
  .checked-mode {
    background-color: #333;
    color: white;
  }
  
  .light-mode {
    background-color: #fff;
    color: black;
  }
  
  body.transition {
    transition: background-color 0.5s ease, color 0.5s ease;
  }
  
  body:not(.transition) {
    transition: none;
  }
.dropdown-modal{
    position: fixed;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0);
    overflow-y: auto;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(201, 201, 201);
}

.autocomplete-text-field-option{
    width: 30vw;
    height: 6vh;
    background-color: rgb(248, 247, 247);
    font-size: 20;

    display: flex;
    justify-content: center;
    align-items: center;

    border-style: solid;
    border-width: 0 0 1px 0;
    border-color: rgb(221, 218, 218);
}

.autocomplete-text-field-container{
    width: 100%;
}
.my-logo {
     
    width: 20vw; /* Valor por defecto para pantallas de laptop */
  }
  
  @media (max-width: 768px) {
    /* Para teléfonos (puedes ajustar el valor del max-width según tus necesidades) */
    .my-logo {
        
      width: 15vw;
    }
  }
  
  @media (min-width: 1200px) {
    /* Para monitores más grandes que una laptop */
    .my-logo {
      width: 10vw;
    }
  }
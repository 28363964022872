.order-card-body{
    display: flex;
}

.order-card-img{
    width: 30vw;
    height: 30vw;
}

.order-card-data{ 
    padding: 0 0 0 5px;
}

.order-card-data-col{ 
    margin: 0 0 0 0px;
    width: 100%;
}

.order-card-data{ 
    font-size: 12px;
}

.order-card-data-rigth-col-data{ 
    display: flex;
    justify-content: end;
    margin: 0 0 0 10px ;
}

.order-card-data-prize{ 
    display: flex;
    justify-content: end;
    font-size: 11px;
    color: rgb(98, 192, 5);
    width: 100%;
}

.order-card-data-rigth-col{ 
    width: 80%;
}

.order-buttons-container{
    display: flex;
    justify-content: end;
}

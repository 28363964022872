.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.modal-content {
    background-color: #fefefe;
    margin: 5% auto;
    padding: 20px;
    width: 80%;
    max-width: 400px;
}

.input {
    margin-bottom: 10px;
    width: 100%;
    padding: 5px;
    border-radius: 25;
}

.close-icon {
    width: 100%;
   display: flex;
   justify-content: end;
    
}

.field-text {
    width: 100%;
}


button {
    padding: 5px 10px;
    margin-right: 10px;
}

.status-clip{
    width: 100%;
    height: 30px;
    padding: 2px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    color: #f3f1f1;
    font-size: 14px;
}

.status-filter{
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    flex-wrap: wrap;
}

.status-filter-count{
    position: absolute;
    top: -8px;
    left: -10px;
    z-index: 10;
    width: 17px;
    height: 17px;
    background-color: #007BFF;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-weight: bold;
    font-size: 10px;
}

.status-filter-button{
    color: #fefefe;
    cursor: pointer;
    width: 20%;
    height: 60px;
    border-radius: 5px;
    margin-left: 15px;
    margin-bottom: 15px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.status-filter-button-checked{
    border-style: solid;
    border-color: rgb(110, 110, 110);
    box-shadow: 0 0 15px rgb(35, 35, 35);
}

.filter-button-label{
    width: 70%;
    padding: 10px;
    text-align: right;
    color: #474747;
}